@tailwind base;
@tailwind components;
@tailwind utilities;

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.is-visible .is-pinned {
  z-index: 100;
}
/*
.sticky-title {
  height: 0;
  overflow: hidden;
}

.is-pinned .sticky-title{
  display: block;
  height: 42px;
}
*/

.modal1--show, .modal1--show .App {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  min-height: -webkit-fill-available;
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left); /* iOS 11.0 */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); /* iOS 11.2 */
}

.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.scrollbar {
  padding-bottom: 4px;
}
.scrollbar::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem !important;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgb(100 116 139/0.16);
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(100 116 139/0.5);
  border-radius: 0.25rem !important;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}